import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { geo_hub_data } from '../Redux/actions';
import report_loader from "../Media/ezgif.com-optimize.gif";
import MUIDataTable from "mui-datatables";
import styles from "../Styles/Table.module.css";
import MiniDrawer from '../components/Minidrawer';
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import WebPage_info from '../components/WebPage_info';

function Geography_data() {
  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id");

  const [geo_data,setGeo_Data] = useState([]);
  const [state_data,setState_Data] = useState([])
  const [city_data,setCity_Data] = useState([])
  const [selected_state,setSelected_State] = useState("ALL")
  const [selected_city,setSelected_City] = useState("ALL")
  const [show, setShow] = useState(false);

  const dispatch = useDispatch();
  let department = storage.getItem("DEPT");
  let USER_GUID = storage.getItem("USER_GUID");
  const check_loading = useSelector((state)=>state.data.loading)


  const navigate = useNavigate();

  // React.useEffect(() => {
  //   if (USER_GUID == "" || USER_GUID == null) {
  //     navigate("/");
  //   }
  // }, [storage]);


const get_state = async()=>{
  try {
    let res =await fetch("https://reportapi.equinoxlab.com/get_states")
    let data = await res.json()  
    if(data.response?.code == "200"){
      setState_Data(data?.Data)
     }else{
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: data?.response?.message,
      });
     }

  } catch (error) {
    
  }
}

const get_city = async(val)=>{
  try {
    let res =await fetch("https://reportapi.equinoxlab.com/get_cities",{
      method:"Post",
      headers:{"Content-Type":"Application/json"},
      body: JSON.stringify({
        "state": val
      })
    })
    let data = await res.json()
 
    if(data.response?.code == "200"){
      setCity_Data(data?.Data)
     }else{
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: data?.response?.message,
      });
     }
  } catch (error) {
    
  }
}

const get_total =(val)=>{

  if(val == "M"){
   let total_count = geo_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.M)
  },0)
  return  new Intl.NumberFormat("en-IN").format(total_count);
}
else if(val == "M1"){
   let total_count = geo_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.M1)
  },0)
  return  new Intl.NumberFormat("en-IN").format(total_count);
}
else if(val == "M2"){
   let total_count = geo_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.M2)
  },0)
  return  new Intl.NumberFormat("en-IN").format(total_count);
}
else if(val == "M3"){
   let total_count = geo_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.M3)
  },0)
  return  new Intl.NumberFormat("en-IN").format(total_count);
}
else if(val == "M4"){
   let total_count = geo_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.M4)
  },0)
  return  new Intl.NumberFormat("en-IN").format(total_count);
}
else  if(val == "M5"){
   let total_count = geo_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.M5)
  },0)
  return  new Intl.NumberFormat("en-IN").format(total_count);
}
else if(val == "M6"){
   let total_count = geo_data?.reduce((acc,ele)=>{
     return acc+Number(ele?.M6)
  },0)
  return  new Intl.NumberFormat("en-IN").format(total_count);
}
else if(val == "Q"){
 let total_count = geo_data?.reduce((acc,ele)=>{
   return acc+Number(ele?.Q)
},0)
return  new Intl.NumberFormat("en-IN").format(total_count);
}
else if(val == "Q1"){
 let total_count = geo_data?.reduce((acc,ele)=>{
   return acc+Number(ele?.Q1)
},0)
return  new Intl.NumberFormat("en-IN").format(total_count);
}
else if(val == "Q2"){
 let total_count = geo_data?.reduce((acc,ele)=>{
   return acc+Number(ele?.Q2)
},0)
return  new Intl.NumberFormat("en-IN").format(total_count);
}
else if(val == "Q3"){
 let total_count = geo_data?.reduce((acc,ele)=>{
   return acc+Number(ele?.Q3)
},0)
return  new Intl.NumberFormat("en-IN").format(total_count);
}
else if(val == "Q4"){
 let total_count = geo_data?.reduce((acc,ele)=>{
   return acc+Number(ele?.Q4)
},0)
return  new Intl.NumberFormat("en-IN").format(total_count);
}
else if(val == "Q5"){
 let total_count = geo_data?.reduce((acc,ele)=>{
   return acc+Number(ele?.Q5)
},0)
return  new Intl.NumberFormat("en-IN").format(total_count);
}
else if(val == "Q6"){
 let total_count = geo_data?.reduce((acc,ele)=>{
   return acc+Number(ele?.Q6)
},0)
   return  new Intl.NumberFormat("en-IN").format(total_count);
}
else if(val == "Y"){
 let total_count = geo_data?.reduce((acc,ele)=>{
   return acc+Number(ele?.Y)
},0)
   return  new Intl.NumberFormat("en-IN").format(total_count);
}
else if(val == "Y1"){
 let total_count = geo_data?.reduce((acc,ele)=>{
   return acc+Number(ele?.Y1)
},0)
   return  new Intl.NumberFormat("en-IN").format(total_count);
}
else if(val == "Y2"){
 let total_count = geo_data?.reduce((acc,ele)=>{
   return acc+Number(ele?.Y2)
},0)
   return  new Intl.NumberFormat("en-IN").format(total_count);
}
else if(val == "Y3"){
 let total_count = geo_data?.reduce((acc,ele)=>{
   return acc+Number(ele?.Y3)
},0)
   return  new Intl.NumberFormat("en-IN").format(total_count);
}
else if(val == "Y4"){
 let total_count = geo_data?.reduce((acc,ele)=>{
   return acc+Number(ele?.Y4)
},0)
   return  new Intl.NumberFormat("en-IN").format(total_count);
}
else if(val == "Y5"){
 let total_count = geo_data?.reduce((acc,ele)=>{
   return acc+Number(ele?.Y5)
},0)
   return  new Intl.NumberFormat("en-IN").format(total_count);
}
else if(val == "Y6"){
  let total_count = geo_data?.reduce((acc,ele)=>{
    return acc+Number(ele?.Y6)
 },0)
 return new Intl.NumberFormat("en-IN").format(total_count);
}
else{
   let x= 1;
 }


}

  useEffect(()=>{
    if(Role_id != "" && Role_id != null && Role_id != "null"){
      get_state();
    }
  },[])


  useEffect(()=>{
    if(Role_id != "" && Role_id != null && Role_id != "null"){
      if(selected_state != "" && selected_city != ""){
        dispatch(geo_hub_data(setGeo_Data,department,selected_state,selected_city))
      }
    }
  },[selected_state,selected_city])

const handleState=(e)=>{
  setSelected_City("ALL")
  if(e.target.value == "ALL"){
    setCity_Data([])
    setSelected_State("ALL")
    setSelected_City("ALL")
  }
  setSelected_State(e.target.value)
   get_city(e.target.value)
}

  const options = {
    filterType: "checkbox",
  };
  const columns = [
    {
      name: "State",
      label: "State",
      options: {
        filter: true,
        sort: false,
        // customHeadRender: ()=>{
        //   return<div style={{backgroundColor:"#007cc3", padding:"10px",textAlign:"left"}}><p style={{marginTop:"3%"}}>Lead Category</p></div>
        // },
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 0,
            // minWidth:"200px",
            // maxWidth:"200px"
          }
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101
          }
        }),
        customBodyRender: (row) => {
          return <div style={{textAlign:"left",paddingRight:"30px"}}><p style={{marginLeft:"20%" }}>{row}</p></div>
        },
      },
    },
    {
      name: "city",
      label: "city",
      options: {
        filter: true,
        sort: false,
        // setCellProps: () => ({
        //   style: { minWidth: "130px", maxWidth: "auto" },
        // }),

        customBodyRender: (row) => {
         
          return <div style={{textAlign:"left",paddingRight:"10px"}}><p style={{marginLeft:"30%"}}>{row}</p></div>
        },
  
      },
    },
    
    // (USER_GUID == "1dc4da5a-c0ab-4d12-ad55-f13d8ce1ad5a" || USER_GUID == "a07b4768-cbab-4269-829b-bfbb17722385") ?{
      (department == "Tech" || department == "Admin" || department == "Management")?{
      name: "Department",
      label: "Department",
      options: {
        filter: true,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "140px", maxWidth: "auto" },
        }),
        customBodyRender: (row) => {
          return <div style={{textAlign:"left"}}><p style={{marginLeft:"30%"}}>{row}</p></div>
        },
      },
    }:{
      name: "Department",
      label: "Department",
      options: {
        filter: true,
        sort: false,
        display:"none"
      }
    },
  {
      name: "M",
      label: "M",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
             return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
        },
      },
    },
    {
      name: "M1",
      label: "M1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
          return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>
        },
      },
    },
    {
      name: "M2",
      label: "M2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
          return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
        },
      },
    },
    {
      name: "M3",
      label: "M3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
             return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
        },
      },
    },
    {
      name: "M4",
      label: "M4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
             return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
        },
      },
    },
    {
      name: "M5",
      label: "M5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
             return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
        },
      },
    },
    {
      name: "M6",
      label: "M6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
             return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
        },
      },
    },
    {
      name: "Q",
      label: "Q",
      options: {
        filter: false,
        sort: true,
        // customHeadLabelRender: () => {
        //   return (
        //     <>
        //       {!show ? (
        //         <div style={{ display: "flex" }} onClick={() => setShow(true)}>
        //           <p
        //             style={{display: "flex",
        //             alignItems: "center" ,fontSize: "15px",
        //             marginTop: "8px",
        //             marginBottom: "0.4rem"}}
        //           >
        //             Q{" "}
        //             <ArrowDownwardIcon sx={{height: "15px"}} />
        //           </p>{" "}
        //         </div>
        //       ) : (
        //         <p style={{ verticalAlign: "middle",   marginTop: "0",
        //         marginBottom: "0.1rem" }}>Q</p>
        //       )}
        //     </>
        //   );
        // },


        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
            //  return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
             return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
        },
      },
    },
    {
      name: "Q1",
      label: "Q1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
             return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
        },
      },
    },
    {
      name: "Q2",
      label: "Q2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
             return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
        },
      },
    },
    {
      name: "Q3",
      label: "Q3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
             return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
        },
      },
    },
    {
      name: "Q4",
      label: "Q4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
             return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
        },
      },
    },
    {
      name: "Q5",
      label: "Q5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
             return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
        },
      },
    },
    {
      name: "Q6",
      label: "Q6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
             return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
        },
      },
    },
    {
      name: "Y",
      label: "Y",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
             return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
        },
      },
    },
    {
      name: "Y1",
      label: "Y1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),

        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
          return (
            <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>
          );
        },
      },
    },
    {
      name: "Y2",
      label: "Y2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
             return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
        },
      },
    },
    {
      name: "Y3",
      label: "Y3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
             return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
        },
      },
    },
    {
      name: "Y4",
      label: "Y4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
             return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
        },
      },
    },
    {
      name: "Y5",
      label: "Y5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),

        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
             return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
        },
      },
    },
    {
      name: "Y6",
      label: "Y6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 0 &&
                  Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 20000
                ? "#dff3ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 20000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 40000
                ? "#9fdcff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 40000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 60000
                ? "#60c5ff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 60000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) < 80000
                ? "#20aeff"
                :     Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                      Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000
                ? "#008edf"
                :    Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000 
                ?
                "#1D5D9B":
                "white",
                padding:"10px",
                color:(( Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) >= 80000 &&
                Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) <= 150000)||Number(cellValue?.props?.children?.props?.children?.replaceAll(",","")) > 150000) ?"white":"black"
          },
        }),
        customBodyRender: (row) => {
          if(row == "0.00"){
            return <div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>0</p></div>
          }
             return<div style={{textAlign:"center" ,padding:"10px"}}><p style={{marginLeft:"20%" }}>{new Intl.NumberFormat("en-IN").format(row)}</p></div>;
        },
      },
    },
  ];

  return (
    <>
    <MiniDrawer/>
    <div className='container-fluid pt-3'>
         { check_loading ? <img src={report_loader} id={styles.loader} />:
     <div className="row" id={geo_data?.length == 0?"":"table_s6"} >

<MUIDataTable
            title={
              <h5 style={{ textAlign: "left" }}>Revenue - Citywise</h5>
            }
            data={geo_data}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              filter: false,
              print: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              // tableBodyMaxHeight: "60vh",
              rowsPerPageOptions:[10,25,50,100],
              sortOrder: {
                name: 'Q',
                direction: 'desc'
              },
              customToolbar: () => {
                return (
                  <>
                     <select  id={styles.payment_drop} name="" value={selected_state} onChange={handleState}>
                      {/* <option >Select State</option> */}
                      <option value="ALL" >All</option>
                      {state_data?.map((ele)=>(
                        <option value={ele.State_name}>{ele.State_name}</option>
                      ))}
                     </select>
                     <select id={styles.city_drop} name="" value={selected_city} onChange={(e)=>setSelected_City(e.target.value)}>
                      <option >Select City</option>
                      {city_data?.map((ele)=>(
                        <option value={ele.City}>{ele.City}</option>
                      ))}
                     </select>
                  </>
                  )
                },
                customTableBodyFooterRender: (
                  count,
                  page,
                  rowsPerPage,
                  changeRowsPerPage,
                  changePage,
                  data
                ) => {
                  const startIndex = page * rowsPerPage;
                  const endIndex = (page + 1) * rowsPerPage;
                  return (
                    <TableFooter>
                      <TableRow id={styles.table_footer}>
                      <TableCell  
                     id={styles.first_col}>
                <p >Total</p>
                      </TableCell>
                        <TableCell colspan={(department == "Tech" || department == "Admin" || department == "Management")?2:1}>
                          {/* <p >Total</p>  */}
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M1")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M2")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M3")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M4")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M5")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("M6")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q1")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q2")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q3")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q4")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q5")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Q6")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y1")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y2")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y3")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y4")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y5")}</p> 
                        </TableCell>
                        <TableCell>
                          <p >{get_total("Y6")}</p> 
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  );
                },
              setTableProps: () => {
                return {
                  padding: 0,
                };
              },
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
            }}
          />
          </div>}
    </div>
    <WebPage_info
     data={[
      "This dashboard displays revenue generated by state and city with therir department. By default, it shows revenue for all states and cities. You can select a specific state or city from the dropdown menus to view revenue for the chosen location.It also includes a search option to focus on a specific state or city for detailed insights."
     ]}
    />
    </>
  )
}

export default Geography_data