import React, { useEffect } from "react";
import styles from "../Styles/Login.module.css";
import { useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import login_cover from "../Media/t/8454.jpg";

import logo from "../Media/equinox_logo.png";
function Login() {
  const storage = window.sessionStorage;
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [disable_btn, setDisable_Btn] = useState(false);
  const navigate = useNavigate();

  //   useEffect(()=>{
  //     if(window.location.href != "http://localhost:3000/"){

  //     if (window.location.protocol == 'http:') {

  //       window.location.href =
  //           window.location.href.replace(
  //                   'http:', 'https:');
  //   }
  // }

  //   },[])
  const check_login = async () => {
    try {
      let res = await fetch(
        "https://orion.equinoxlab.com/api/User.svc/User_login",
        {
          method: "Post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            MOBILE: mobile,
            PASSWORD: password,
          }),
        }
      );
      let data = await res.json();
      setDisable_Btn(false);
      
      if (data.response[0].MESSAGE == "Insert valid Login id and Password") {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }, 
        });

        Toast.fire({
          icon: "error",
          title: "Please enter right credential",
        });
      }

      if (data.response[0].MESSAGE == "Please check user id or password") {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "Please check user id or password",
        });
      }

      if (data.response[0].CODE == "200") {
        if (data.data[0].ROLE_ID == "a758b712-1aaa-445a-a629-df0c3dde9061") {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            text: "Unauthorised User",
            customClass: {
              htmlContainer: 'custom-html-container',
            },
          });
        } else {
          storage.setItem("USER_GUID", data?.data[0]?.USER_GUID);
          storage.setItem("profile_img", data?.data[0]?.PROFILE_IMG);
          storage.setItem("Role_id", data?.data[0]?.ROLE_ID);
          storage.setItem("DEPT_ID", data?.data[0]?.DEPT_ID);
          storage.setItem("DEPT", data?.data[0]?.DEPT);
          storage.setItem("EMP_CODE", data?.data[0]?.EMP_CODE);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            text: "Login successful",
            customClass: {
              htmlContainer: 'custom-html-container',
            },
          });
          if (
            data?.data[0]?.DEPT == "Management" ||
            data?.data[0]?.DEPT == "Tech" ||
            data?.data[0]?.DEPT == "Admin" ||
            data?.data[0]?.DEPT == "Corporate Sales" ||
            data?.data[0]?.DEPT == "FnB Sales"
          ) {
            navigate("/new_vs_repeated_business");
          } else if (data?.data[0]?.DEPT == "Labs") {
            navigate("/lab_wise_data");
          } else if (data?.data[0]?.DEPT == "Marketing") {
            // navigate("/nps");
            navigate("/marketing_leadsource");
          }
          // else if(data.data[0].DEPT == "Tech"){
          //   navigate("/logistics_report");
          // }
          else if (data?.data[0]?.DEPT == "Accounts") {
            navigate("/payment_report");
          } else if (data?.data[0]?.DEPT == "Office of CEO") {
            navigate("/hr_team_s6");
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 1500,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "info",
              text: "Unauthorised User",
              customClass: {
                htmlContainer: 'custom-html-container',
              },
            });
          }
        }
      }else{
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          text: data.response[0].MESSAGE,
          customClass: {
            htmlContainer: 'custom-html-container',
          },
        });
      }

    } catch (error) {
      setDisable_Btn(false);
    
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Please enter right credential",
        customClass: {
          htmlContainer: 'custom-html-container',
        },
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisable_Btn(true);
    check_login();
  };

  return (
    <div
      className="container-fluid"
      style={{ marginTop: "-4em", marginLeft: "-4em" }}
    >
      <div className="row">
        {/* <div className='col-7'> */}
        <div className="col-sm-12 col-md-5" id={styles.left_part}>
          <div id={styles.header_logo}>
            <img
              src={logo}
              alt=""
              id={styles.logo_pic}
              className="img-fluid mt-4"
            />
          </div>
          <h2 id={styles.header}>MRIS</h2>
          <p>Please Enter Your Details</p>
          <form onSubmit={handleSubmit} action="" id={styles.form_contain}>
            <div className="col-11 mt-3">
              <div class="form-group pt-2">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <PersonIcon sx={{ color: "black" }} />
                    </span>
                  </div>
                  <input
                    type="number"
                    class="form-control"
                    onChange={(e) => setMobile(e.target.value)}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    placeholder="Mobile no. / Employee Code"
                    required="required"
                  />
                </div>
              </div>

              <div class="form-group pt-4 pb-1">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <VpnKeyIcon sx={{ color: "black" }} />
                    </span>
                  </div>
                  <input
                    type="password"
                    class="form-control"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    required="required"
                  />
                </div>
              </div>
            </div>

            {/* <div className="col-11 mt-3">
                <label htmlFor="" >
                  Password
                </label>
                <input
                  type="password"
                  className="form-control mt-1"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div> */}
            <button
              type="submit"
              className="btn btn-primary col-6 mt-4"
              disabled={disable_btn ? true : false}
            >
              Log In
            </button>
          </form>
        </div>

        <div className="col-sm-12 col-md-6" id={styles.img_contain}>
          <img src={login_cover} alt="" className="img-fluid" />
        </div>
      </div>
    </div>
  );
}

export default Login;
