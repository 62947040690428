import React, { useEffect, useState } from "react";
import eq_logo from "../Media/equinox_logo.png";
import MiniDrawer from "../components/Minidrawer";
import styles from "../Styles/Table.module.css";
import Total_leads_tbl from "../components/Total_leads_tbl";
import report_loader from "../Media/ezgif.com-optimize.gif";
import moment from "moment";
import WebPage_info from "../components/WebPage_info";
import { DateRangePicker, Stack } from "rsuite";
import "rsuite/dist/rsuite.min.css";

function Leads_leader() {
  const storage = window.sessionStorage;
  let Role_id = storage.getItem("Role_id")
  let emp_code = storage.getItem("EMP_CODE");

  const [salesperson_data, setSalesperson_Data] = useState([]);
  const [assigned_data, setAssigned_Data] = useState([]);
  const [leads_added_data, setLeads_Added_Data] = useState([]);

  const [salesperson_load, setSalesperson_Load] = useState(false);
  const [assigned_load, setAssigned_Load] = useState(false);
  const [leads_added_load, setLeads_added_Load] = useState(false);

  const [total_leads,setTotal_Leads] = useState(0);
  const [total_converted,setTotal_Converted] = useState(0);
  const [total_conversion,setTotal_Conversion] = useState(0);
  
  const [assigned_total,setAssigned_Total] = useState(0);
  const [assigned_converted,setAssigned_Converted] = useState(0);
  const [assigned_conversion,setAssigned_Conversion] = useState(0);

  let date = new Date(),
  y = date.getFullYear(),
  m = date.getMonth();

  const [selectedFromDate,setSelectedFromDate] = useState(new Date(y, m, 1))
  const [selectedToDate,setSelectedToDate] = useState(new Date(y, m + 1, 0))

  const [added_total,setAdded_Total] = useState(0);
  const [added_converted,setAdded_Converted] = useState(0);
  const [added_conversion,setAdded_Conversion] = useState(0)


  const handleDateRange = (new_val) => {
    if (new_val !== null) {
      setSelectedFromDate(new_val[0]);
      setSelectedToDate(new_val[1]);
    } else {
      setSelectedFromDate(new Date(y, m, 1));
      setSelectedToDate(new Date(y, m + 1, 0));
    }
  };

  const get_converted_leads_salesperson = async (signal) => {
    setSalesperson_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/total_converted_leads_by_salesperson_tl`,
        {
          method: "POST",
          signal:signal,
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            EMP_CODE_INPUT: emp_code,
            START_DATE: moment(selectedFromDate).format("YYYY-MM-DD"),
            END_DATE: moment(selectedToDate).format("YYYY-MM-DD"),
          }),
        }
      );
      let data = await res.json();
      setSalesperson_Load(false);
      setSalesperson_Data(data?.Data);

      let total_lead = data?.Data?.reduce((acc,ele)=>{
        return acc+Number(ele?.total_lead)
     },0)
      let total_converted = data?.Data?.reduce((acc,ele)=>{
        return acc+Number(ele?.converted)
     },0)

     setTotal_Leads(total_lead);
     setTotal_Converted(total_converted);
     let temp = (total_converted/total_lead)*100;

     setTotal_Conversion(temp?.toFixed(2))

    } catch (error) {
    if(error.name == "AbortError"){
        setSalesperson_Load(true);
      }else{
        setSalesperson_Load(false);
      }

    }
  };

  const get_leads_assigned_marketing = async (signal) => {
    setAssigned_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/leads_assigned_by_marketing_tl`,
        {
          method: "POST",
          signal:signal,
          headers: { "Content-Type": "Application/Json"},
          body: JSON.stringify({
            EMP_CODE_INPUT: emp_code,
            START_DATE: moment(selectedFromDate).format("YYYY-MM-DD"),
            END_DATE: moment(selectedToDate).format("YYYY-MM-DD"),
          }),
        }
      );
      let data = await res.json();
      setAssigned_Load(false);
      setAssigned_Data(data?.Data);
      let total_assigned = data?.Data?.reduce((acc,ele)=>{
        return acc+Number(ele?.total_lead)
     },0)
      let total_converted = data?.Data?.reduce((acc,ele)=>{
        return acc+Number(ele?.converted)
     },0)
    setAssigned_Total(total_assigned)
    setAssigned_Converted(total_converted)
    let temp = (total_converted/total_assigned)*100
    setAssigned_Conversion(temp?.toFixed(2))
    } catch (error) {
      if(error.name == "AbortError"){
      setAssigned_Load(true);
      }else{
        setAssigned_Load(false);
      }
    }
  };

  const get_leads_added_corporate = async (signal) => {
    setLeads_added_Load(true);
    try {
      let res = await fetch(
        `https://reportapi.equinoxlab.com/leads_added_by_corporate_tl`,
        {
          method: "POST",
          signal:signal,
          headers: { "Content-Type": "Application/Json" },
          body: JSON.stringify({
            EMP_CODE_INPUT: emp_code,
            START_DATE: moment(selectedFromDate).format("YYYY-MM-DD"),
            END_DATE: moment(selectedToDate).format("YYYY-MM-DD"),
          }),
        }
      );
      let data = await res.json();
      setLeads_added_Load(false);
      setLeads_Added_Data(data?.Data);
      let total_lead = data?.Data?.reduce((acc,ele)=>{
        return acc+Number(ele?.total_lead)
     },0)
      let total_converted = data?.Data?.reduce((acc,ele)=>{
        return acc+Number(ele?.converted)
     },0)

     setAdded_Total(total_lead);
     setAdded_Converted(total_converted);
     let temp = (total_converted/total_lead)*100;

     setAdded_Conversion(temp?.toFixed(2))


    } catch (error) {
      if(error.name == "AbortError"){
      setLeads_added_Load(true);
      }
      else{
        setLeads_added_Load(false);
      }
    }
  };


  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
      const controller = new AbortController();
      const signal = controller.signal;
    get_converted_leads_salesperson(signal);
    get_leads_assigned_marketing(signal);
    get_leads_added_corporate(signal);
    return () => {
      controller.abort();
    };
    }
  }, [selectedFromDate,selectedToDate]);
  return (
    <>
      <MiniDrawer />
      <div className="container-fluid pt-3">
  {/* {assigned_load || salesperson_load || leads_added_load?<></>:  */}
  <div className="row">
  <div className="col-sm-12 col-md-6">  <h4 style={{textAlign:"left",fontSize:"30px",color:"#007cc3",fontWeight:"500"}}>Leads Leader</h4></div>
  <div className="col-sm-12 col-md-6" id={styles.leads_header}>
      <div>
    <p htmlFor="">Created on</p>
 {/* {assigned_load || salesperson_load ||  leads_added_load ? <></>:  */}
 <DateRangePicker
                            format="dd MMM yyyy"
                            value={[selectedFromDate, selectedToDate]}
                            onChange={handleDateRange}
                            size="md"
                            style={{width:"100%"}}
                            placement="bottomEnd"
                            preventOverflow
                          />
                          {/* } */}
  </div>
  </div>
</div>
{/* } */}
<div className="row mt-4">
  <div className="col-sm-12 col-md-6">
  {assigned_load ? (
                <img src={report_loader} id={styles.leads_loader} />
              ) : (
                <>
                  <div className="row" id={styles.leader_right_part}>
                    <div className="col-3">
                      <p>Lead Assigned</p>
                      <h5>{assigned_total}</h5>
                    </div>
                    <div className="col-3">
                      <p>Converted</p>
                      <h5>{assigned_converted}</h5>
                    </div>
                    <div className="col-3">
                      <p>Convertion Ratio</p>
                      <h5>{assigned_conversion}%</h5>
                    </div>
                  </div>
                  <div className="row mt-3 mb-5">
                    <Total_leads_tbl
                      header_name={"Leads Assigned Marketing"}
                      data={assigned_data}
                    />
                  </div>
                
                </>
              )}
  </div>
  <div className="col-sm-12 col-md-6">
  {salesperson_load ? (
                <img src={report_loader} id={styles.leads_loader} />
              ) : (
                <>
                 <div className="row" id={styles.leader_right_part}>
                  <div className="col-3">
                      <p>Total Lead</p>
                      <h5>{total_leads}</h5>
                    </div>
                    <div className="col-3">
                      <p>Converted</p>
                      <h5>{total_converted}</h5>
                    </div>
                    <div className="col-3">
                      <p>Convertion Ratio</p>
                      <h5>{total_conversion}%</h5>
                    </div>
                  </div>
                  <div className="row mt-3">
                    {" "}
                    <Total_leads_tbl
                      header_name={"Total Leads"}
                      data={salesperson_data}
                    />
                  </div>
                 
                </>
              )}
  </div>
</div>
<div className="row mt-4 mb-5">
  <div className="col-sm-12 col-md-6">
  {leads_added_load ? (
                <img src={report_loader} id={styles.leads_loader} />
              ) : (
                <>
                  <div className="row" id={styles.leader_right_part}>
                  <div className="col-3">
                      <p>Lead Added</p>
                      <h5>{added_total}</h5>
                    </div>
                    <div className="col-3">
                      <p>Converted</p>
                      <h5>{added_converted}</h5>
                    </div>
                    <div className="col-3">
                      <p>Convertion Ratio</p>
                      <h5>{added_conversion}%</h5>
                    </div>
                  </div>
                  <div className="row mt-3">
                    {" "}
                    <Total_leads_tbl
                      header_name={"Leads Added"}
                      data={leads_added_data}
                    />
                  </div>
                
                </>
              )}
  </div>
</div>

    
      </div>
      <WebPage_info
       data={
        ["This dashboard displays the number of leads assigned by Marketing to each department, the count of those leads converted into customers,and the total number of leads added. It provides a clear overview of lead progression and conversion."]
       }
      />
    </>
  );
}

export default Leads_leader;
