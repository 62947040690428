import React, { useState, useEffect, useRef, useCallback } from "react";
import MiniDrawer from "../components/Minidrawer";
import D3Funnel from "d3-funnel";
import Swal from "sweetalert2";
import { Dropdown } from "primereact/dropdown";
// import Candidate_source_chart from "../components/Candidate_source_chart";
import CheckIcon from '@mui/icons-material/Check';
import report_loader from "../Media/ezgif.com-optimize.gif";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "../Styles/HR_Team.module.css";
import Candidate_source_chart from "../components/Candidate_source_chart";
import WebPage_info from "../components/WebPage_info";

function Profile_Metrics() {
  const storage = window.sessionStorage;
//   let user_id = storage.getItem("USER_GUID");
  const navigate = useNavigate();
  let Role_id = storage.getItem("Role_id");

  const funnel_ref = useRef(null);
  const [dept_list, setDept_list] = useState([]);
  const [dept, setDept] = useState("");
  const [req, setReq] = useState("");
  const [req_list, setReq_list] = useState([]);
  const [funnel_data, setFunnel_data] = useState([]);
  const [loader1, setLoader1] = useState(false);
  const [showMsg, setShowMsg] = useState(false)
  const [showInitial, setShowInitial] = useState(true)
  const [disableSubmit, setDisableSubmit] = useState(false)

  const get_department = async () => {
    try {
      let res = await fetch(
        "https://orion.equinoxlab.com/api/Department_Master.svc/Get_Department_Dropdown"
      );
      let data = await res.json();
      setDept_list(data.data);
    } catch (error) {
      ;
    }
  };

  let req_array = [];
  const get_req = async () => {
    try {
      let res = await fetch(
        // "https://venus.equinoxlab.com/api/Dashboard.svc/Get_Funnel_Req"
        `https://venus.equinoxlab.com/api/Dashboard.svc/Get_Funnel_Req_Dept_Wise/${dept}`
      );
      let data = await res.json();
      setReq_list([]);
      data?.funnel_data?.forEach((ele) => {
        req_array.push({
          value: ele.REQ_ID,
          label: ele.POSITION_NAME,
        });
        setReq_list(req_array);
      });
    } catch (error) {
      ;
    }
  };

  const handleSelected = (selected) => {
    setReq(selected.value);
    setDisableSubmit(false)
  };

  const handleChangeDept = (e) => {
    setDept(e.target.value);
    setShowMsg(true)
    setDisableSubmit(true)
  };

  const get_funnel_data = async () => {
    if (req == "") {
      setDisableSubmit(false)
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: false,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: "error",
        title: "Please select requisition",
      });
    } else {
      try {
        setLoader1(true);
        let res = await fetch(
          `https://venus.equinoxlab.com/api/Dashboard.svc/Get_Profile_Wise_Funnel/${dept}/${req}`
        );
        let data = await res.json();
        setFunnel_data(data.hrround_data);
        setLoader1(false);
        setShowMsg(false)
        setShowInitial(false)
        setDisableSubmit(true)
          //*********************DRAW FUNNEL CHART****************
          const chart = new D3Funnel(funnel_ref?.current);
          chart.draw(data?.hrround_data, options);
      } catch (error) {
        ;
      }
    }
  };
  
  const options = {
    label: {
      enabled: true,
      fontFamily: null,
      fontSize: "14px",
      fill: "#000",
      format: "{l}: {f}",
    },
    block: {
      barOverlay: false,
      highlight: false,
      dynamicHeight: false,
      minHeight: 15,
      fill: {
        type: "solid",
        scale: [
          "#007cc3",
          "#7ED7C1",
          " #9ADE7B",
          "#FFC7C7",
          "#FFE3BB",
          "#B0A695",
          "#DCBFFF",
          "#87C4FF",
          "#FFD099",
        ],
      },
    },
    tooltip: {
      enabled: false,
      format: "{l}: {v}",
    },
    chart: {
      width: 350,
      height: 400,
      // bottomWidth: 1 / 3,
      bottomPinch: 1,
      inverted: false,
      horizontal: false,
      animate: 170,
      curve: {
        enabled: true,
        height: 40,
      },
      totalCount: null,
    },
  };

  useEffect(() => {
    if(Role_id != "" && Role_id != null && Role_id != "null"){
      get_department();
      get_req();
    }
  }, [dept]);

//   useEffect(() => {
//     if (user_id === "" || user_id === null) {
//       navigate("/");
//     }
//   }, [storage]);

  const handleClick = () => {
    get_funnel_data();
    // get_other_data();
  };

  // *********searchable Dropdown
  const selectedReqTemplate = (option, props) => {
    if (option) {
      return (
        <div
          className="flex align-items-center"
          style={{ padding: "1px 4px 1px 8px" }}
        >
          <div>{option.label}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const reqOptionTemplate = (option) => {
    return (
      <div
        className="flex align-items-center"
        style={{ backgroundColor: "white", padding: "1px 8px 1px 8px" }}
      >
        <div>{option.label}</div>
      </div>
    );
  };

  return (
    <>
      <MiniDrawer header_name="Profile Metrics" />
      <div
        className="container-fluid"
        style={{
          marginTop: "1%",
          marginLeft: "1%",
          width: "98%",
        }}
      >
        <div className="col-md-12">
          {/* Dropdowns */}
          <div className="row">
            <div
              className="col-md-6"
            //   style={{ borderRight: "1px solid #cacaca", }}
              style={{borderRight: showMsg || showInitial || funnel_data== null || loader1 ? "" : "1px solid #cacaca", }}
            >
              <div>
                <div className="row mt-2">
                  <div
                    className="col-md-3"
                    style={{
                      display: "flex",
                      alignItems: "end",
                      paddingRight: "20px",
                      paddingLeft:"0px",
                    }}
                  >
                    <h4 style={{ marginBottom: "0px", fontSize:"22px" }}>Profile Funnel</h4>
                  </div>
                  <div
                    className="col-md-9"
                    style={{ display: "flex", paddingLeft: "0px" }}
                  >
                    <div
                      className="col-md-5"
                      style={{ textAlign: "left", paddingRight: "5px" }}
                    >
                      {/* <label
                        style={{
                          marginRight: "5px",
                          marginTop: "7px",
                          fontWeight: "bold",
                          fontSize:"16px",
                        }}
                      >
                        Department:
                      </label> */}
                      <select
                        name=""
                        id=""
                        className="form-control"
                        onChange={handleChangeDept}
                        style={{ backgroundColor: "white", cursor:"pointer" }}
                        value={dept}
                      >
                        <option value="" selected>
                          select Department
                        </option>
                        {dept_list?.map((ele) => (
                          <option value={ele.id}>{ele.text}</option>
                        ))}
                      </select>
                    </div>

                    {dept ? (
                      <>
                        <div
                          className="col-md-6"
                          style={{
                            textAlign: "left",
                            paddingLeft: "0px",
                            paddingRight: "11px",
                          }}
                        >
                          {/* <label
                            style={{
                              marginRight: "5px",
                              marginTop: "7px",
                              fontWeight: "bold",
                              fontSize:"16px"
                            }}
                          >
                            Requisition:
                          </label> */}

                          <div className="card flex justify-content-center">
                            <Dropdown
                              value={req}
                              onChange={handleSelected}
                              options={req_list}
                              optionLabel="label"
                              placeholder="Select Requisition"
                              filter
                              valueTemplate={selectedReqTemplate}
                              itemTemplate={reqOptionTemplate}
                              className={styles.searchableDropdown}
                            />
                          </div>
                        </div>
                        <div
                          className="col-md-1"
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "center",
                            // paddingLeft:"20px",
                          }}
                        >
                          <button
                            className="btn btn-primary"
                            onClick={handleClick}
                            style={{ color:'white' }}
                            disabled={disableSubmit}
                          >
                            <CheckIcon />
                          </button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>

              {/* Funnel */}
              <div
                style={{
                  marginTop: "2%",
                  display: loader1 ? "none" : "flex",
                  justifyContent: "center",
                }}
              >
              
                <div>
                  <div style={{ 
                    visibility: !showMsg ? "visible" : "hidden"
                  }} id="funnel" ref={funnel_ref}></div>
                </div>

                {showMsg || showInitial ? 
                  <div style={{maxWidth:"35vw", position:"absolute", top:"50vh" }}>
                    <h6 style={{color:"#999"}}>
                      Please select department and requisition to view Profile Funnel.
                    </h6>
                  </div>
                  :
                  (funnel_data == null ?
                  <div style={{ maxWidth:"25vw" }}>
                    <h6 style={{color:"#999"}}>
                      Data not available.
                    </h6>
                  </div> :
                  <></>)
                }
              </div>

              <div
                style={{
                //   marginTop: "25vh",
                  display: loader1 ? "flex" : "none",
                  justifyContent: "center",
                  alignItems: "center",
                //   borderRight:loader1 ? "1px solid #cacaca" : ""
                }}
              >
                <img src={report_loader} alt="" />
              </div>
            </div>

            {/* Dounghnut chart */}
            <div className="col-md-6">
              <Candidate_source_chart />
            </div>
          </div>
        </div>
      </div>
      <WebPage_info
       data={
        ["The Proflie Funnel represents the overview of requisitions and the number of candidates going through each of the stages of hiring process starting from candidate added all the way to the offer accepted. Source Chart donut shows the total number of candidates added via a variety of sources, including Apna, Naukri, and LinkedIn."]
       }
      />
    </>
  );
}

export default Profile_Metrics;
