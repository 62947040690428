import React from "react";
import styles from "../Styles/Table.module.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { get_data } from "../Redux/actions";


function Hub_tbl({ data, date, setDate }) {

  const storage = window.sessionStorage;

  let Role_id = storage.getItem("Role_id");
  let dept = storage.getItem("DEPT");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const dispatch= useDispatch();
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };

const handleSearch = ()=>{
  dispatch(get_data(date,dept ))
}

const handleDate = (e) =>{

  setDate(e?.target?.value)
}

  return (
    <Paper
      sx={{
        // width: index == 0 ? "40%" : "20%",
        mb: 2,
        padding: "0px",

      }}
    >
      <TableContainer sx={{ maxHeight: "550px" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            sx={{ backgroundColor: "#007cc3", color: "#444a4f !important",position:"sticky",top:0,zIndex:"999" }} 
          >
            <TableRow>
            <TableCell id={styles.th_unit1} colspan={13}>
            <h5 className="h5 mt-2" style={{ color: "white", textAlign: "left" , marginLeft:"1%"}}>
            Samples Tested vs Revenue
                </h5>
            </TableCell>
            </TableRow>
            <TableRow>
              <TableCell id={styles.th_unit1} rowSpan={2}> <h6 className="h6 mt-2" style={{ color: "white" }}>Year</h6></TableCell>
              <TableCell
                id={styles.th_unit1}
                style={{ textAlign: "center" }}
                colSpan={2}
              >
                <h6 className="h6 mt-2" style={{ color: "white", textAlign: "center" }}>
                  Bangalore
                </h6>
              </TableCell>
              <TableCell
                id={styles.th_unit1}
                style={{ textAlign: "center" }}
                colSpan={2}
              >
                <h6 className="h6 mt-2" style={{ color: "white", textAlign: "center" }}>Chennai</h6>
              </TableCell>
              <TableCell
                id={styles.th_unit1}
                style={{ textAlign: "center" }}
                colSpan={2}
              >
                <h6 className="h6 mt-2" style={{ color: "white", textAlign: "center" }}>
                  Hyderabad
                </h6>
              </TableCell>

              <TableCell
                id={styles.th_unit1}
                style={{ textAlign: "center" }}
                colSpan={2}
              >
                <h6 className="h6 mt-2" style={{ color: "white", textAlign: "center" }}>Kolkata</h6>
              </TableCell>
              <TableCell
                id={styles.th_unit1}
                style={{ textAlign: "center" }}
                colSpan={2}
              >
                <h6 className="h6 mt-2" style={{ color: "white", textAlign: "center" }}>Mumbai</h6>
              </TableCell>
              <TableCell
                id={styles.th_unit1}
                style={{ textAlign: "center" }}
                colSpan={2}
              >
                <h6 className="h6 mt-2" style={{ color: "white", textAlign: "center" }}>Delhi</h6>
              </TableCell>
            </TableRow>
            <TableRow>
              {/* <TableCell id={styles.th_unit1}>
                <h6 className="h6 mt-2" style={{ color: "white" }}>Year</h6>
              </TableCell> */}
              {/* <TableCell id={styles.th_unit1}>
                <h6 className="h6 mt-2" style={{ color: "white" }}>Month</h6>
              </TableCell> */}
              <TableCell id={styles.th_unit1}>
                <h6 className="h6 mt-2" style={{ color: "white" }}>Count</h6>
              </TableCell>
              <TableCell id={styles.th_unit1}>
                <h6 className="h6 mt-2" style={{ color: "white" }}>Revenue (₹)</h6>
              </TableCell>
              <TableCell id={styles.th_unit1}>
                <h6 className="h6 mt-2" style={{ color: "white" }}>Count</h6>
              </TableCell>
              <TableCell id={styles.th_unit1}>
                <h6 className="h6 mt-2" style={{ color: "white" }}>Revenue (₹)</h6>
              </TableCell>
              <TableCell id={styles.th_unit1}>
                <h6 className="h6 mt-2" style={{ color: "white" }}>Count</h6>
              </TableCell>
              <TableCell id={styles.th_unit1}>
                <h6 className="h6 mt-2" style={{ color: "white" }}>Revenue (₹)</h6>
              </TableCell>
              <TableCell id={styles.th_unit1}>
                <h6 className="h6 mt-2" style={{ color: "white" }}>Count</h6>
              </TableCell>
              <TableCell id={styles.th_unit1}>
                <h6 className="h6 mt-2" style={{ color: "white" }}>Revenue (₹)</h6>
              </TableCell>
              <TableCell id={styles.th_unit1}>
                <h6 className="h6 mt-2" style={{ color: "white" }}>Count</h6>
              </TableCell>
              <TableCell id={styles.th_unit1}>
                <h6 className="h6 mt-2" style={{ color: "white" }}>Revenue (₹)</h6>
              </TableCell>
              <TableCell id={styles.th_unit1}>
                <h6 className="h6 mt-2" style={{ color: "white" }}>Count</h6>
              </TableCell>
              <TableCell id={styles.th_unit1}>
                <h6 className="h6 mt-2" style={{ color: "white" }}>Revenue (₹)</h6>
              </TableCell>
            </TableRow>
          </TableHead>
          {data?.length == "0" ? (
            <TableBody>
              {" "}
              <TableRow>
                <TableCell colSpan={13} id={styles.td_unit} >
                  {/* <div style={{ margin: "auto", border: "1px solid red" }}> */}
                  <p style={{ textAlign: "center" }}>No Data Available</p>
                </TableCell>
                {/* </div> */}
              </TableRow>
            </TableBody>
          ) : (
            <TableBody id={styles.body_content}>
              {(rowsPerPage > 0
                ? data?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : data
              )?.map((ele) => (
                <TableRow>
                  <TableCell id={styles.td_unit}>
                    <p className="p mt-2" >{ele?.Collected_Year}</p>
                  </TableCell>
                  {/* <TableCell id={styles.td_unit}>
                    <p className="p mt-2" >{ele?.Collected_Month}</p>
                  </TableCell> */}
                  <TableCell id={styles.td_unit}>
                    <p className="p mt-2" >{ele?.Data?.Bangalore?.Total_Collected}</p>
                  </TableCell>
                  <TableCell id={styles.td_unit}>
                    <p className="p mt-2" >{new Intl.NumberFormat("en-IN").format(ele?.Data?.Bangalore?.Revenue)}</p>
                  </TableCell>
                  <TableCell id={styles.td_unit}>
                    <p className="p mt-2" >{ele?.Data?.Chennai?.Total_Collected}</p>
                  </TableCell>
                  <TableCell id={styles.td_unit}>
                    <p className="p mt-2" >{new Intl.NumberFormat("en-IN").format(ele?.Data?.Chennai?.Revenue)}</p>
                  </TableCell>
                  <TableCell id={styles.td_unit}>
                    <p className="p mt-2" >{ele?.Data?.Hyderabad?.Total_Collected}</p>
                  </TableCell>
                  <TableCell id={styles.td_unit}>
                    <p className="p mt-2" >{new Intl.NumberFormat("en-IN").format(ele?.Data?.Hyderabad?.Revenue)}</p>
                  </TableCell>
                  <TableCell id={styles.td_unit}>
                    <p className="p mt-2" >{ele?.Data?.Kolkata?.Total_Collected}</p>
                  </TableCell>
                  <TableCell id={styles.td_unit}>
                    <p className="p mt-2" >{new Intl.NumberFormat("en-IN").format(ele?.Data?.Kolkata?.Revenue)}</p>
                  </TableCell>
                  <TableCell id={styles.td_unit}>
                    <p className="p mt-2" >{ele?.Data?.Mumbai?.Total_Collected}</p>
                  </TableCell>
                  <TableCell id={styles.td_unit}>
                    <p className="p mt-2" >{new Intl.NumberFormat("en-IN").format(ele?.Data?.Mumbai?.Revenue)}</p>
                  </TableCell>
                  <TableCell id={styles.td_unit}>
                    <p className="p mt-2" >{ele?.Data?.Noida?.Total_Collected}</p>
                  </TableCell>
                  <TableCell id={styles.td_unit}>
                    <p className="p mt-2" >{new Intl.NumberFormat("en-IN").format(ele?.Data?.Noida?.Revenue)}</p>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {/* <TablePagination
        id={styles.footer_div}
        sx={{ backgroundColor: "#007cc3", color: "white" }}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}

export default Hub_tbl;
