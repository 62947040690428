import React from "react";
import MUIDataTable from "mui-datatables";
import styles from "../Styles/Table.module.css";
import report_loader from "../Media/ezgif.com-optimize.gif";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

function BDE_dashboard_tbl_hub({
  data,
  load_func,
  reporting_data,
  reporting_person,
  setReporting_Person,
  header_name,
  Role_id,
}) {
  const storage = window.sessionStorage;
  let user_id = storage.getItem("USER_GUID");

  const get_total = (val) => {
    if (val == "D") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.D);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    }

    if (val == "D1") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.D1);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    }

    if (val == "D2") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.D2);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    }

    if (val == "D3") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.D3);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    }

    if (val == "D4") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.D4);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    }

    if (val == "D5") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.D5);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    }

    if (val == "D6") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.D6);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    }

    if (val == "W") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.W);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    }

    if (val == "W1") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.W1);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    }

    if (val == "W2") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.W2);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    }

    if (val == "W3") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.W3);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    }

    if (val == "W4") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.W4);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    }

    if (val == "W5") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.W5);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    }

    if (val == "W6") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.W6);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    }
    if (val == "M") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.M);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "M1") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.M1);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "M2") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.M2);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "M3") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.M3);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "M4") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.M4);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "M5") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.M5);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "M6") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.M6);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "Q") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.Q);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "Q1") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.Q1);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "Q2") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.Q2);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "Q3") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.Q3);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "Q4") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.Q4);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "Q5") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.Q5);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "Q6") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.Q6);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "Y") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.Y);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "Y1") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.Y1);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "Y2") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.Y2);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "Y3") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.Y3);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "Y4") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.Y4);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "Y5") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.Y5);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else if (val == "Y6") {
      let total_count = data?.reduce((acc, ele) => {
        return acc + Number(ele?.Y6);
      }, 0);
      return new Intl.NumberFormat("en-IN").format(total_count);
    } else {
      let x = 1;
    }
  };

  const options = {
    filterType: "checkbox",
  };
  const columns = [
    {
      name: "NAME",
      label: "Name",
      options: {
        filter: true,
        sort: true,

        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 0,
            // minWidth:"210px",
            // maxWidth:"210px"
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 1000,
          },
        }),
        //  customHeadLabelRender: () => {
        //         return (
        //           <>
        //               <div style={{ display: "flex",justifyContent:"left" }} >
        //                 <span id="name_bde">Name</span>
        //               </div>

        //           </>
        //         );
        //       },
        customBodyRender: (row) => {
          return (
            <div style={{ textAlign: "left", paddingRight: "20px" }}>
              <p style={{ marginLeft: "20%" }}>{row}</p>
            </div>
          );
        },
      },
    },
    {
      name: "D",
      label: "D",
      options: {
        filter: false,
        sort: true,
        draggable: {
          enabled: true,
          transitionTime: 300,
        },
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "30px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "D1",
      label: "D1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "D2",
      label: "D2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "D3",
      label: "D3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "D4",
      label: "D4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "D5",
      label: "D5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "D6",
      label: "D6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "W",
      label: "W",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "W1",
      label: "W1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "W2",
      label: "W2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "W3",
      label: "W3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "W4",
      label: "W4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "W5",
      label: "W5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "W6",
      label: "W6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "M",
      label: "M",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "M1",
      label: "M1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "M2",
      label: "M2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "M3",
      label: "M3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "M4",
      label: "M4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "M5",
      label: "M5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "M6",
      label: "M6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },

    {
      name: "Q",
      label: "Q",
      options: {
        filter: false,
        sort: true,
        // customHeadLabelRender: () => {
        //   return (
        //     <>
        //       {!show ? (
        //         <div style={{ display: "flex" }} onClick={() => setShow(true)}>
        //           <p
        //             style={{display: "flex",
        //             alignItems: "center" ,fontSize: "15px",
        //             marginTop: "8px",
        //             marginBottom: "0.4rem"}}
        //           >
        //             Q{" "}
        //             <ArrowDownwardIcon sx={{height: "15px"}} />
        //           </p>{" "}
        //         </div>
        //       ) : (
        //         <p style={{ verticalAlign: "middle",   marginTop: "0",
        //         marginBottom: "0.1rem" }}>Q</p>
        //       )}
        //     </>
        //   );
        // },
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q1",
      label: "Q1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q2",
      label: "Q2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q3",
      label: "Q3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q4",
      label: "Q4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q5",
      label: "Q5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Q6",
      label: "Q6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y",
      label: "Y",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y1",
      label: "Y1",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),

        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y2",
      label: "Y2",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y3",
      label: "Y3",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y4",
      label: "Y4",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y5",
      label: "Y5",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25?"#E64848":
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 2000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) < 4000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    4000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) < 6000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    6000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) < 8000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    8000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    10000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  10000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                8000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  10000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 10000
                ? "white"
                : "black",

            //  color:((Number(cellValue?.props?.children?.replaceAll(",","")) >= 8000 &&
            //  Number(cellValue?.props?.children?.replaceAll(",","")) < 10000)||Number(cellValue?.props?.children?.replaceAll(",","")) >= 10000 || ( Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
            //  Number(cellValue?.props?.children?.replaceAll(",","")) < 25))?"white":"black"
          },
        }),

        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
    {
      name: "Y6",
      label: "Y6",
      options: {
        filter: false,
        sort: true,
        setCellProps: (cellValue) => ({
          style: {
            backgroundColor:
              // Number(cellValue?.props?.children?.replaceAll(",","")) >= 0 &&
              // Number(cellValue?.props?.children?.replaceAll(",","")) < 25
              //   ? "#E64848"
              //   :
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
              Number(cellValue?.props?.children?.replaceAll(",", "")) < 100000
                ? "#dff3ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    100000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    1000000
                ? "#9fdcff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    1000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    2000000
                ? "#60c5ff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    2000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    3000000
                ? "#20aeff"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                    3000000 &&
                  Number(cellValue?.props?.children?.replaceAll(",", "")) <
                    4000000
                ? "#008edf"
                : Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                  4000000
                ? "#1D5D9B"
                : "white",
            padding: "20px",
            // color:
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     8000 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) <
            //       10000) ||
            //   Number(cellValue?.props?.children?.replaceAll(",", "")) >=
            //     10000 ||
            //   (Number(cellValue?.props?.children?.replaceAll(",", "")) >= 0 &&
            //     Number(cellValue?.props?.children?.replaceAll(",", "")) < 25)
            //     ? "white"
            //     : "black",
            color:
              (Number(cellValue?.props?.children?.replaceAll(",", "")) >=
                3000000 &&
                Number(cellValue?.props?.children?.replaceAll(",", "")) <
                  4000000) ||
              Number(cellValue?.props?.children?.replaceAll(",", "")) >= 4000000
                ? "white"
                : "black",
          },
        }),
        customBodyRender: (row) => {
          return <>{new Intl.NumberFormat("en-IN").format(row)}</>;
        },
      },
    },
  ];
  return (
    <>
      {load_func ? (
        <img
          style={{ marginTop: "-10px" }}
          src={report_loader}
          id={styles.loader}
        />
      ) : (
        <div id={data?.length == 0 ? "" : "client_s6"}>
          <MUIDataTable
            title={<h5 style={{ textAlign: "left" }}>{header_name}</h5>}
            data={data}
            columns={columns}
            options={{
              options: options,
              selectableRows: "none",
              viewColumns: false,
              filter: false,
              print: false,
              responsive: "standard",
              download: false,
              fixedHeader: true,
              pagination: true,
              rowsPerPageOptions: [10, 25, 50, 100],
              // tableBodyMaxHeight: "60vh",
              sortOrder: {
                name: "Q",
                direction: "desc",
              },
              customToolbar: () => {
                return (
                  Role_id == "a758b712-1aaa-445a-a629-df0c3dde9064" || Role_id == "a758b712-1aaa-445a-a629-df0c3dde9059" ?
                    <></>
                  :
                  <>
                    <span>Reporting Person:</span>
                    <select
                      onChange={(e) => setReporting_Person(e.target.value)}
                      style={{ height: "30px" }}
                      id={styles.user_drop}
                      value={reporting_person}
                      name=""
                    >
                      <option value={null}>All</option>
                  {reporting_data?.map((ele)=>(
 <option value={ele?.USER_GUID}>{ele?.Name}</option>
                  ))}
                     

                      {/* {reporting_data?.map((ele) => (
                        <>
                          {ele?.USER_GUID == "159" ? (
                            user_id ==
                            "1dc4da5a-c0ab-4d12-ad55-f13d8ce1ad5a" ? (
                              <option value={ele?.USER_GUID}>
                                {ele?.Name}
                              </option>
                            ) : (
                              <></>
                            )
                          ) : (
                            <option value={ele?.USER_GUID}>{ele?.Name}</option>
                          )}
                        </> */}
                   
                    </select>
                  </>
                );
              },
              setTableProps: () => {
                return {
                  padding: "default",
                };
              },
              customTableBodyFooterRender: (
                count,
                page,
                rowsPerPage,
                changeRowsPerPage,
                changePage,
                data
              ) => {
                const startIndex = page * rowsPerPage;
                const endIndex = (page + 1) * rowsPerPage;
                return (
                  <TableFooter id={styles.table_footer}>
                    <TableRow>
                      <TableCell id={styles.first_col}>
                        <p>Total</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("D")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("D1")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("D2")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("D3")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("D4")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("D5")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("D6")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("W")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("W1")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("W2")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("W3")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("W4")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("W5")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("W6")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("M")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("M1")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("M2")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("M3")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("M4")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("M5")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("M6")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("Q")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("Q1")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("Q2")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("Q3")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("Q4")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("Q5")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("Q6")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("Y")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("Y1")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("Y2")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("Y3")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("Y4")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("Y5")}</p>
                      </TableCell>
                      <TableCell>
                        <p>{get_total("Y6")}</p>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                );
              },
              textLabels: {
                body: {
                  noMatch: "Data Not Available",
                },
              },
            }}
          />
        </div>
      )}
    </>
  );
}

export default BDE_dashboard_tbl_hub;
