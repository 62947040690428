import React, { useEffect, useState } from 'react'
import MiniDrawer from '../components/Minidrawer'
import MUIDataTable from "mui-datatables";
import { useDispatch } from 'react-redux';
import moment from "moment";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Salesperson_Sample_Type_count from '../components/Salesperson_Sample_Type_count';
import Salesperson_sample_Type_Revenue from '../components/Salesperson_sample_Type_Revenue';
import styles from "../Styles/Table.module.css"
import { get_salesperson_client_type_count_data, get_salesperson_client_type_revenue_data } from '../Latest_addition_redux/actions';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import WebPage_info from '../components/WebPage_info';

function Client_Type_Sample_Type() {

    const storage = window.sessionStorage;
    let department = storage.getItem("DEPT");
    let Role_id = storage.getItem("Role_id");
  
    const [alignment, setAlignment] = React.useState("left");
  
    const [selectedFromDate, setSelectedFromDate] = useState(new Date());
    const [selectedToDate, setSelectedToDate] = useState(new Date());
    const [start_date, setStart_Date] = useState(new Date());
    const [end_date, setEnd_Date] = useState(new Date());
  
    // const [selectedFromDate_revenue, setSelectedFromDate_Revenue] = useState();
    // const [selectedToDate_revenue, setSelectedToDate_Revenue] = useState();
    // const [start_date_revenue, setStart_Date_Revenue] = useState(new Date());
    // const [end_date_revenue, setEnd_Date_Revenue] = useState(new Date());
  
    const [range_flag, setRange_Flag] = useState(false);
  
    const [params, setParams] = useState("MTD");
    // const [params_revenue, setParams_Revenue] = useState("");
  
    const [sample_count_data, setSample_Count_Data] = useState([]);
    const [sample_load, setSample_Load] = useState(false);
  
    const [sample_revenue_data, setSample_Revenue_Data] = useState([]);
    const [revenue_load, setRevenue_Load] = useState(false);
  
    const [client_list, setClient_List] = useState([]);
    const [display1,setDisplay1] = useState(false)
    const [display2,setDisplay2] = useState(false)
  
    const dispatch = useDispatch();
  
    const handleAlignment = (event, newAlignment) => {
      let newStartDate = moment(selectedFromDate).format("YYYY-MM-DD");
      let newEndDate = moment(selectedToDate).format("YYYY-MM-DD");
      if (newAlignment != null) {
        setAlignment(newAlignment);
        if (newAlignment == "left") {

          dispatch(
            get_salesperson_client_type_count_data(
              setSample_Load,
              department,
              params,
              newStartDate,
              newEndDate,
              setSample_Count_Data
            )
          );
        }
        if (newAlignment == "right") {

          dispatch(
            get_salesperson_client_type_revenue_data(
              setRevenue_Load,
              department,
              params,
              newStartDate,
              newEndDate,
              setSample_Revenue_Data
            )
          );
        }
      }
    };
  
    const handleDateRange_count = (e) => {
      setParams(e.target.value);
      if (e.target.value == "Custom Date") {
        setRange_Flag(true);
      } else {
        setRange_Flag(false);
        setSelectedFromDate(new Date());
        setSelectedToDate(new Date());
          setStart_Date(new Date());
          setEnd_Date(new Date());
        dispatch(
          get_salesperson_client_type_count_data(
            setSample_Load,
            department,
            e.target.value,
            start_date,
            end_date,
            setSample_Count_Data
          )
        );
      }
    };
  
    const handleDateRange_revenue = (e) => {
      setParams(e.target.value);
      if (e.target.value == "Custom Date") {
        setRange_Flag(true);
      } else {
        setRange_Flag(false);
        setSelectedFromDate(new Date());
        setSelectedToDate(new Date());
        setStart_Date(new Date());
        setEnd_Date(new Date());
        dispatch(
          get_salesperson_client_type_revenue_data(
            setRevenue_Load,
            department,
            e.target.value,
            start_date,
            end_date,
            setSample_Revenue_Data
          )
        );
      }
    };
  
    const handleDateChange = (new_val) => {
      // if (new_val !== null) {
      //   setSelectedFromDate(new_val[0]);
      //   setSelectedToDate(new_val[1]);
      //   let newStartDate = moment(new_val[0]?.$d).format("YYYY-MM-DD");
      //   let newEndDate = moment(new_val[1]?.$d).format("YYYY-MM-DD");
      //   setStart_Date(newStartDate);
      //   setEnd_Date(newEndDate);
      // }else{
      //   setParams("MTD")
      //   setRange_Flag(false)
      //   setSelectedFromDate();
      //   setSelectedToDate();
      //   setStart_Date(new Date());
      //   setEnd_Date(new Date());
      //   dispatch(
      //     get_salesperson_client_type_count_data(
      //       setSample_Load,
      //       department,
      //      "MTD",
      //       start_date,
      //       end_date,
      //       setSample_Count_Data
      //     )
      //   );

      // }

      if (new_val !== null) {
        setSelectedFromDate(new_val[0]);
        setSelectedToDate(new_val[1]);
  
        let newStartDate = moment(new_val[0]).format("YYYY-MM-DD");
        let newEndDate = moment(new_val[1]).format("YYYY-MM-DD");
  
        dispatch(
          get_salesperson_client_type_count_data(
            setSample_Load,
            department,
            params,
            newStartDate,
            newEndDate,
            setSample_Count_Data
          )
        );
        setDisplay2(true)
      } else {
        setParams("MTD")
        setRange_Flag(false)
        setSelectedFromDate(new Date());
        setSelectedToDate(new Date());
        const fromattedDate = dayjs(new Date());
        let new_date = moment(fromattedDate.$d).format("YYYY-MM-DD");
        dispatch(
          get_salesperson_client_type_count_data(
            setSample_Load,
            department,
            "MTD",
            new_date,
            new_date,
            setSample_Count_Data
          )
        );
      }
    };
  
    const handleDateChange_revenue = (new_val) => {
      // if (new_val !== null) {
      //   setSelectedFromDate(new_val[0]);
      //   setSelectedToDate(new_val[1]);
      //   let newStartDate = moment(new_val[0]?.$d).format("YYYY-MM-DD");
      //   let newEndDate = moment(new_val[1]?.$d).format("YYYY-MM-DD");
      //   setStart_Date(newStartDate);
      //   setEnd_Date(newEndDate);

      // }else{
      //   setParams("MTD")
      //   setRange_Flag(false)
      //   setSelectedFromDate();
      //   setSelectedToDate();
      //   setStart_Date(new Date());
      //   setEnd_Date(new Date());
      //   dispatch(
      //     get_salesperson_client_type_revenue_data(
      //       setRevenue_Load,
      //       department,
      //       "MTD",
      //       start_date,
      //       end_date,
      //       setSample_Revenue_Data
      //     )
      //   );

      // }

      if (new_val !== null) {
        setSelectedFromDate(new_val[0]);
        setSelectedToDate(new_val[1]);
  
        let newStartDate = moment(new_val[0]).format("YYYY-MM-DD");
        let newEndDate = moment(new_val[1]).format("YYYY-MM-DD");
  
        dispatch(
          get_salesperson_client_type_revenue_data(
            setRevenue_Load,
            department,
            params,
            newStartDate,
            newEndDate,
            setSample_Revenue_Data
          )
        );

      } else {
        setParams("MTD")
        setRange_Flag(false)
        setSelectedFromDate(new Date());
        setSelectedToDate(new Date());
        const fromattedDate = dayjs(new Date());
        let new_date = moment(fromattedDate.$d).format("YYYY-MM-DD");
        dispatch(
          get_salesperson_client_type_revenue_data(
            setRevenue_Load,
            department,
            "MTD",
            new_date,
            new_date,
            setSample_Revenue_Data
          )
        );

      }
    };
  
    const handle_click_count = () => {

      if(selectedFromDate == undefined && selectedToDate == undefined){
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "Please Select Date",
        });
      }else{
        dispatch(
          get_salesperson_client_type_count_data(
            setSample_Load,
            department,
            params,
            start_date,
            end_date,
            setSample_Count_Data
          )
        );
      }
     
      // setDisplay1(false)
    };
  
    const handle_click_revenue = () => {
      if(selectedFromDate == undefined && selectedToDate == undefined){
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "Please Select Date",
        });
      }else{
      dispatch(
        get_salesperson_client_type_revenue_data(
          setRevenue_Load,
          department,
          params,
          start_date,
          end_date,
          setSample_Revenue_Data
        )
      );
        }
      // setDisplay2(false)
    };
  
    // const get_client_name = async () => {
    //   try {
    //     let res = await fetch("https://reportapi.equinoxlab.com/get_client_type");
    //     let data = await res.json();
    //     setClient_List(data?.Data);
    //   } catch (error) {
    //     ;
    //   }
    // };
  
    useEffect(() => {
      // get_client_name();
      if(Role_id != "" && Role_id != null && Role_id != "null"){
        dispatch(
          get_salesperson_client_type_count_data(
            setSample_Load,
            department,
            "MTD",
            start_date,
            end_date,
            setSample_Count_Data
          )
        );
      }
    }, []);
  


    
  
  return (
    <>
        <MiniDrawer/>
    <div className='container-fluid pt-3'>
    <div
          className="row"
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {/* <div
            className="col-md-6 col-sm-12"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-end",
              marginTop: "10px",
            }}
          >
            <p style={{ color: "#303030" }}>
              Showing data from
              <span style={{ fontWeight: "700", color: "black" }}> 20/12 </span>
              to
              <span style={{ fontWeight: "700", color: "black" }}> 27/12 </span>
            </p>
          </div> */}
          <div
            className="col-md-6 col-sm-12"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "7px",
              marginBottom: "10px",
              marginRight:"1vw"
            }}
          >
            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
              id={styles.toggle_btn}
            >
              <ToggleButton value="left" size="small" aria-label="Small sizes">
                No. of samples
              </ToggleButton>
              <ToggleButton value="right" size="small" aria-label="Small sizes">
                Revenue
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>

        <div className="row">
          {alignment == "left" ? (
            <Salesperson_Sample_Type_count
              handleDateRange={handleDateRange_count}
              range_flag={range_flag}
              selectedFromDate={selectedFromDate}
              selectedToDate={selectedToDate}
              handleDateChange={handleDateChange}
              data={sample_count_data}
              sample_load={sample_load}
              handleClick={handle_click_count}
              client_list={client_list}
              display1={display1}
              params_count={params}
            />
          ) : (
            <></>
          )}

          {alignment == "right" ? (
            <Salesperson_sample_Type_Revenue
              handleDateRange={handleDateRange_revenue}
              range_flag={range_flag}
              selectedFromDate={selectedFromDate}
              selectedToDate={selectedToDate}
              handleDateChange={handleDateChange_revenue}
              data={sample_revenue_data}
              revenue_load={revenue_load}
              handleClick={handle_click_revenue}
              display2={display2}
              params_revenue={params}
            />
          ) : (
            <></>
          )}
        </div>
    </div>
    <WebPage_info
    data={
      ["This dashboard displays the number of samples for each sample type, categorized by salesperson. It includes a toggle to switch to revenue view,to see the revenue generated for the corresponding sample count."]
    }
    />
    </>
  )
}

export default Client_Type_Sample_Type